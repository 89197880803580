const Admin = () => {
  return (
    <div>
      <h1>Admin Page</h1>
      <p>Welcome to the Admin Page!</p>
    </div>
  );
};

export default Admin;
