import { Link } from 'react-router-dom';
import './Menu.css'; // CSS для стилей

const Menu = ({ closeMenu, menuOpen }) => {
  return (
    // <div className="menu">
    <div className={menuOpen ? 'menu open' : 'menu'}>
      <button className="close-button" onClick={closeMenu}>
        X
      </button>
      <nav>
        <ul>
          <li>
            <Link to="/" onClick={closeMenu}>
              Home
            </Link>
          </li>
          <li>
            <Link to="/admin" onClick={closeMenu}>
              Admin
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Menu;
