const Home = () => {
  return (
    <div className="home">
      <h1>Home Page</h1>
      <p>Welcome to the Home Page!</p>
      <section>
        <video height="600" autoPlay muted>
          <source src="test_video.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </section>
    </div>
  );
};

export default Home;
