import { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Menu from './components/Menu/Menu.js';
import Home from './pages/Home/Home.js';
import Admin from './pages/Admin/Admin.js';
import './App.css';

function App() {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
    console.log('Menu button is pressed');
  };
  return (
    <Router>
      <div className="app">
        <button className="menu-button" onClick={toggleMenu}>
          ☰
        </button>
        {/* <Menu className={menuOpen ? 'menu-open' : 'menu'} /> */}
        <Menu menuOpen={menuOpen} closeMenu={() => setMenuOpen(false)} />
        {/* {menuOpen && ()} */}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/admin" element={<Admin />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
